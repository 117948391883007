html,
body {
  height: 100%;
  margin: 0;
}

.main-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125vh; /* changed from 100% to 100vh */
  width: 100%;
  background: #333333;
}

.main-loading-animation {
    position: relative;
    width: 350px;
    height: 350px;
    border-radius: 50%;
    background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
    animation: loadingAnimation 1.2s linear infinite;
    margin: 0 auto; /* added to center the animation horizontally */
  }
  
  @keyframes loadingAnimation {
    0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
  }
  .main-loading-animation span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
}

.main-loading-animation span:nth-child(1) {
    filter: blur(5px);
}

.main-loading-animation span:nth-child(2) {
    filter: blur(10px);
}

.main-loading-animation span:nth-child(3) {
    filter: blur(25px);
}

.main-loading-animation span:nth-child(4) {
    filter: blur(50px);
}

.main-loading-animation::after { /* changed from .main-loading-animation:after */
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: #333333;
    border: solid #333333 10px;
    border-radius: 50%;
  }

  .main-loading-text {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    color: white;
    opacity: 0;
    animation: fadeInOut 2s infinite;
    text-align: center;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }