/* General styles */
.App {
  text-align: center;
  display: flex;
  background-color: #282c34;
  color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.Developer-mode-tag {
  font-size: 2em;
  margin: 0.67em 0;
  position: fixed;
  text-align: right;
  align-self: end;
  columns: auto;
}

.html {
  height: 100%;
  width: 80%;
}

.body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.modal-backdrop.show {
  position: fixed;
  top: 0;
  left: 0;
  width: 160vw;
  height: 160vh;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.774);
}

.modal-backdrop.fade {
  width: 160vw;
  height: 160vh;
  opacity: 0;
}

.loading-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 0, 0);
  padding: 20px;
  border: 1px solid #000000;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  border-radius: 10px;
}

.loading-animation {
  width: 50px;
  height: 50px;
  border: 3px solid white;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto; /* Center the loading animation horizontally */
  margin-top: 20px; /* Add top margin for spacing */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  font-family: "Apple Pro", Arial, sans-serif;
  color: white;
  margin-top: 20px;
}

.Alert-Notif {
  position: fixed;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  max-width: 400px;
  height: 140px;
  margin-left: -400px;
}

.sidemenu {
  width: 240px;
  padding: 10px;
  height: 100%;
  background-color: #202123;
}

/* Server status */

.openai-status-tracker {
  margin-top: auto;
  background-color: #3d3d3c;
  border-radius: 8px;
  padding: 10px;
  display: inline-block;
}

.openai-status-tracker .status {
  font-weight: bold;
  font-size: 6px;
}

.openai-status-tracker .server-performance {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
}

.openai-status-tracker .operational {
  background-color: green;
}

.openai-status-tracker .down {
  background-color: red;
}

.openai-status-tracker .performance-low {
  background-color: red;
}

.openai-status-tracker .performance-medium {
  background-color: yellow;
}

.openai-status-tracker .performance-high {
  background-color: green;
}
/* Adjust styles for smaller screens */
@media (max-width: 768px) {
  .sidemenu {
    width: 100%;
  }
  .scroll-to-latest {
    left: 50%;
    transform: translateX(-50%);
    display: none; /* Hide the button by default */
  }
  .scroll-to-latest.visible {
  display: block; /* Show the button when the menu is not at max width */
}
}

.Theme Switch{
  padding: 12px;
  border: 1px solid white;
  border-radius: 5px;
  text-align: left;
  transition: ease 0.25s all;
}

.side-menu-button {
  padding: 12px;
  border: 1px solid white;
  border-radius: 5px;
  text-align: left;
  transition: ease 0.25s all;
}

.scroll-to-latest {
  background-color: #0000008e;
  padding: 12px;
  margin-top: 1rem;
  color: #ffffff;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  text-align: center;
  transition: ease 0.25s all;
  position: fixed;
}

.scroll-to-latest:hover {
  border-color: #fff;
}

.System-Submit-Button {
  padding: 10px;
  margin: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #343541;
  color: #fff;
  transition: ease 0.25s all;
}

.System-Submit-Button:hover {
  background-color: white;
  border: 1px solid white;
  color: #202123;
}

.confirm-msg {
  color: green;
}

.chat-thread-list {
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  overflow-y: auto;
  transition: ease 0.25s all;
}

.chat-thread-title {
  padding: 10px;
  margin: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  transition: ease 0.5s all;
}

.chat-thread-title.active {
  background-color: #fff;
  color: #000;
  transition: ease 0.5s all;
}

.chat-thread-title button {
  background-color: transparent;
  border: none;
  color: grey;
  margin-left: 10px;
  cursor: pointer;
  transition: ease 0.25s all;
}

.chat-thread-title button:hover {
  color: red;
}

.side-menu-button span {
  padding-left: 6px;
  padding-right: 12px;
}

.side-menu-button:hover {
  background-color: rgba(233, 233, 233, 0.329);
}

.fade Alert-Notif alert alert-danger alert-dismissible show {
  display: flex;
  position: absolute;
}

#Google-Signin {
  position: absolute;
  bottom: 10px;
  left: 30px;
  padding-bottom: 0%;
  background-color: #fff;
  color: #000;
  border-radius: 1rem;
  border-color: #fff;
  transition: all 0.3s ease;
  text-align: end;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font: bold;
}

#Google-Signin:hover {
  border-color: #000;
  background-color: #000;
  color: #fff;
}

.google-logo {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

/* Hamburger menu */
.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.line {
  width: 24px;
  height: 3px;
  background-color: white;
  margin-bottom: 4px;
  transition: transform 0.3s ease;
}

.hamburger.open .line:nth-child(1) {
  transform: rotate(45deg) translate(4px, 4px);
}

.hamburger.open .line:nth-child(2) {
  opacity: 100;
}

.hamburger.open .line:nth-child(3) {
  transform: rotate(-45deg) translate(4px, -4px);
}


.hamburger.is-open:hover .line:nth-child(1) {
  transform: rotate(-20deg) translate(2px, 2px);
}

.hamburger.is-open:hover .line:nth-child(2) {
  transform:  translate( 3px, 0px);
  opacity: 100;
}

.hamburger.is-open:hover .line:nth-child(3) {
  transform: rotate(20deg) translate(2px, -2px);
  
}

.hamburger:hover .line:nth-child(1) {
  transform: rotate(20deg) translate(4px, 2px);
}

.hamburger:hover .line:nth-child(2) {
  transform:  translate( -3px, 0px);
  opacity: 100;
}

.hamburger:hover .line:nth-child(3) {
  transform: rotate(-20deg) translate(4px, -2px);
  
}

/* Chat Box CSS */

.chat-input-holder {
  display: inline;
  padding: 2px;
  bottom: 0;
  left: 240px;
  right: 0;
  background-color: rgba(0, 0, 0, 0.041);
}

.Chat-box-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  background-image: linear-gradient(to bottom, #ffffff 0%, #757575 16.66%, #202020 33.33%, #000000 50%, #202020 66.66%, #757575 73.33%, #ffffff 100%);
  position: relative;
  background-size: 100% 400%;
  overflow-y: auto;
  animation: movingGradient linear 50s infinite;
  animation-fill-mode: forwards;
}

.chat-input-textarea {
  padding: 12px;
  color: white;
  font-size: 1.25em;
  background-color: #40414f;
  width: 90%;
  border-radius: 5px;
  border: none;
  margin: 24px;
  outline: none;
  box-shadow: 0 0 8px 0 rgb(0, 0, 0, 0.25);
  position: sticky;
  bottom: 0;
}

.calculated-message {
  text-align: center;
}
.chat-log {
  flex-grow: 1;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  text-align: left;
  overflow-y: auto;
}

/* Code block section */
.Code-Block-Visibility {
  background-color: #282c34;
  border: none;
  margin: 1rem;
  color: white;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.25s ease-in;
}

.code-block {
  font-family: 'Ubuntu', sans-serif;
  background-color: #000;
  border: 5px solid #111;
  padding: 10px;
  margin: 10px 0;
  overflow-x: auto;
  color: #000;
  border-radius: 5px;
  filter: blur(1px);
  transition: all 0.25s ease;
}

.code-block:hover {
  border: 5px solid #fff;
  filter: blur(0px);
}

.code-block-header {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
  color: #888;
  margin-bottom: 5px;
}

.code-block.element.style {
  background-color: #282c34;
}

.code {
  font-family: 'Ubuntu', sans-serif;
}

.copy-button {
  position: right;
  background-color: #282c34;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: all 0.25s ease-in;
}

.copy-button:hover {
  color: #000;
  background-color: #fff;
}

.copy-button:active {
  background-color: green;
}

.copy-button:focus {
  outline: none;
  background-color: green;
}

.language {
  color: grey;
  position: left;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

/* Avatar and chat message styles */

.Button-Holder{
  align-items: end;
}

.Edit-Button{
  background-color: #0000008e;
  padding: 12px;
  color: #ffffff;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  text-align: center;
  transition: ease 0.25s all;
  margin: 1rem;
}

.Switch-Messages{
  background-color: #0000008e;
  padding: 12px;
  color: #ffffff;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  text-align: center;
  transition: ease 0.25s all;
  margin: 1em;
}


.chat-message.chatgpt {
  background-color: #444654;
  border-radius: 5px;
}

.chat-message.false {
  background-color: #282c34;
  border-radius: 5px;
}

.chat-message-center {
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 12px;
  padding-left: 4px;
  padding-right: 4px;
}

.avatar {
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.avatar.chatgpt {
  background: #0da37f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.avatar.false {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #000000;
}

.message {
  padding-left: 40px;
  padding-right: 40px;
}

.message {
  margin: 20px;
  font-family: Arial, sans-serif;
}

@media (max-width: 768px) {
  .message {
    padding-left: 20px;
    padding-right: 20px;
    margin: 10px;
    font-size: 14px; 
    font-family: Arial, sans-serif;
  }
  .chat-message-center {
    max-width: 100%;
  }
}

/* Model styles */
.Model-list {
  left: 0;
  right: 10;
}

.model-header {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Table styles */
.Table {
  margin-bottom: 20px;
  box-shadow: 0px 1px 3px rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
  background-color: #000;
  transition: all 0.10s ease-in;
}

.Table:hover {
  background-color: #0000008e;
}

.Table Table {
  width: 100%;
  border-collapse: collapse;
}

.Table th,
.Table td {
  padding: 12px 15px;
  text-align: left;
}

.Table thead {
  color: #f3f3f3;
}

.Table tbody tr:nth-child(odd) {
  color: #f9f9f9;
}

.Table tbody tr:nth-child(even) {
  color: #ffffff;
}

.Table tbody tr:hover {
  color: #e9e9e9;
}

/* Image section */
.image-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.image-section img {
  max-width: 100%;
  height: auto;
  margin: 0.5rem;
}

/* Loading animation section */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.loading-spinner img {
  max-width: 100%;
  height: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes movingGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}
